<template>
  <div>
    <div class="d-flex justify-content-between sticky-top bg-white top-section">
      <div>
        <h3 class="mb-2 payments-table-title">
          {{ $t('payment.paymentTable.paymentsForApproval') }}
        </h3>
        <div class="table-filters">
          <DropdownTableFilter
            :filter-name="$t('payment.paymentTable.filters.reconciliationStatus')"
            :filter-value="reconciliationStatusFilterText"
            trigger="click"
            :clearable="true"
            @on-choose-item="onReconciliationStatusFilterChanged"
            @on-clear-filter="onReconciliationStatusFilterChanged(null)"
          >
            <div slot="filter-value" class="reconciliation-status-filter">
              <span
                class="reconciliation-status-filter-icon"
                :class="{
                  [`bg-${RECONCILIATION_STATUS_COLOR[reconciliationStatusFilter]}`]: true,
                }"
              />
              {{ reconciliationStatusFilterText }}
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="RECONCILIATION_STATUSES.APPROVED">
                <div class="reconciliation-status-filter">
                  <span
                    class="reconciliation-status-filter-icon"
                    :class="{
                      [`bg-${RECONCILIATION_STATUS_COLOR[RECONCILIATION_STATUSES.APPROVED]}`]: true,
                    }"
                  />
                  {{ $t('reconciliation.exports.status.approved') }}
                </div>
              </el-dropdown-item>
              <el-dropdown-item :command="RECONCILIATION_STATUSES.NOT_APPROVED">
                <div class="reconciliation-status-filter">
                  <span
                    class="reconciliation-status-filter-icon"
                    :class="{
                      [`bg-${RECONCILIATION_STATUS_COLOR[RECONCILIATION_STATUSES.NOT_APPROVED]}`]: true,
                    }"
                  />
                  {{ $t('reconciliation.exports.status.notApproved') }}
                </div>
              </el-dropdown-item>
              <el-dropdown-item :command="RECONCILIATION_STATUSES.PENDING">
                <div class="reconciliation-status-filter">
                  <span
                    class="reconciliation-status-filter-icon"
                    :class="{
                      [`bg-${RECONCILIATION_STATUS_COLOR[RECONCILIATION_STATUSES.PENDING]}`]: true,
                    }"
                  />
                  {{ $t('reconciliation.exports.status.pending') }}
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </DropdownTableFilter>
          <DatePickerTableFilter
            :filter-name="$t('payment.paymentTable.filters.paymentDueDate')"
            :filter-value="paymentDueDateFilterText"
            value-format="yyyy-MM"
            type="month"
            size="small"
            align="center"
            :date-range="paymentDueDateFilter"
            :clearable="true"
            :picker-options="pickerOptions"
            @on-date-changed="onPaymentDueDateFilterChanged"
            @on-clear-filter="onPaymentDueDateFilterChanged(null)"
          />
          <DatePickerTableFilter
            :filter-name="$t('payment.paymentTable.headers.reconciliationPeriod')"
            :filter-value="reconciliationPeriodFilterText"
            value-format="yyyy-MM"
            type="month"
            size="small"
            align="center"
            :date-range="reconciliationPeriodFilter"
            :clearable="true"
            :picker-options="pickerOptions"
            @on-date-changed="onBillingDateFilterChanged"
            @on-clear-filter="onBillingDateFilterChanged(null)"
          />
          <DropdownTableFilter
            :filter-name="$t('payment.paymentTable.filters.paymentMethod')"
            :filter-value="reconciliationPaymentMethodFilterText"
            trigger="click"
            :clearable="true"
            @on-clear-filter="onReconciliationPaymentMethodFilterReset"
          >
            <div slot="filter-value" class="reconciliation-payment-method-filter">
              {{ reconciliationPaymentMethodFilterText }}
            </div>
            <el-dropdown-menu slot="dropdown" class="reconciliation-payment-method-dropdown">
              <el-checkbox-group v-model="paymentMethodFilterList" @change="onReconciliationPaymentMethodFilterChanged">
                <el-checkbox :label="PAYMENT_METHODS.BANK_TRANSFER">
                  <div class="reconciliation-payment-method-filter">
                    <div class="reconciliation-payment-method-filter-icon">
                      <BankTransferIcon class="method-icon" />
                    </div>
                    <div class="payment-method-title">
                      {{ $t(`payment.paymentTable.filtersPaymentMethod.${PAYMENT_METHODS.BANK_TRANSFER}`) }}
                    </div>
                  </div>
                </el-checkbox>
                <el-checkbox :label="PAYMENT_METHODS.BANK_TRANSFER_DEBIT">
                  <div class="reconciliation-payment-method-filter">
                    <div class="reconciliation-payment-method-filter-icon">
                      <BankTransferIcon class="method-icon" />
                      <RecurringIcon class="recurring-icon" />
                    </div>
                    <div class="payment-method-title">
                      {{ $t(`payment.paymentTable.filtersPaymentMethod.${PAYMENT_METHODS.BANK_TRANSFER_DEBIT}`) }}
                    </div>
                  </div>
                </el-checkbox>
                <el-checkbox :label="PAYMENT_METHODS.CREDIT_CARD">
                  <div class="reconciliation-payment-method-filter">
                    <div class="reconciliation-payment-method-filter-icon">
                      <CreditCardIcon class="method-icon" />
                    </div>
                    <div class="payment-method-title">
                      {{ $t(`payment.paymentTable.filtersPaymentMethod.${PAYMENT_METHODS.CREDIT_CARD}`) }}
                    </div>
                  </div>
                </el-checkbox>
                <el-checkbox :label="PAYMENT_METHODS.CREDIT_CARD_DEBIT">
                  <div class="reconciliation-payment-method-filter">
                    <div class="reconciliation-payment-method-filter-icon">
                      <CreditCardIcon class="method-icon" />
                      <RecurringIcon class="recurring-icon" />
                    </div>
                    <div class="payment-method-title">
                      {{ $t(`payment.paymentTable.filtersPaymentMethod.${PAYMENT_METHODS.CREDIT_CARD_DEBIT}`) }}
                    </div>
                  </div>
                </el-checkbox>
                <el-checkbox :label="PAYMENT_METHODS.CHECK">
                  <div class="reconciliation-payment-method-filter">
                    <div class="reconciliation-payment-method-filter-icon">
                      <ChequeIcon class="method-icon" />
                    </div>
                    <div class="payment-method-title">
                      {{ $t(`payment.paymentTable.filtersPaymentMethod.${PAYMENT_METHODS.CHECK}`) }}
                    </div>
                  </div>
                </el-checkbox>
              </el-checkbox-group>
            </el-dropdown-menu>
          </DropdownTableFilter>
        </div>
      </div>
      <div class="d-flex align-items-top h-100">
        <ExcelExport :disabled="!sortedImbalances?.length" @on-export-option-choice="exportExcel" />
      </div>
    </div>
    <div>
      <TableLoadingSkeleton v-if="loading" />
      <Table
        v-else
        :columns="columns"
        :data="tableData"
        sticky-columns="2"
        show-index
        border
        rounded
        :hover="false"
        custom-class="payment-table"
      >
        <template #cell-index="{ rowIndex }">
          {{ rowIndex + 1 + pageLimit * (currentPage - 1) }}
        </template>
        <template #cell-paymentDueDate="{ rowData: { paymentDueDate, paymentTerm, paymentStatus }, rowIndex }">
          <PaymentDueDate
            :payment-due-date="paymentDueDate"
            :payment-term="paymentTerm"
            :payment-status="paymentStatus"
            :show="activeDueDatePopover === rowIndex"
            @click="handleDueDateClick(rowIndex)"
          />
        </template>
        <template #cell-paymentTerm="{ rowData: { paymentTerm } }">
          <PaymentTerm :payment-term="paymentTerm" />
        </template>
        <template
          #cell-totalPayment="{
            rowData: {
              totalPayment: { totalBilledAmount, totalPaidAmount },
              unbilledOrders,
              billedAmounts,
              billingIds,
              customer,
            },
          }"
        >
          <TotalPaymentField
            :billed-amounts="billedAmounts"
            :billing-ids="billingIds"
            :customer="customer"
            :total-billed-amount="totalBilledAmount"
            :total-paid-amount="totalPaidAmount"
            :unbilled-orders="unbilledOrders"
            truncated
          />
        </template>
        <template #cell-billedAmounts="{ rowData: { billedAmounts, billingsLeftToBePaid }, rowData }">
          <Button
            v-if="billedAmounts.length"
            type="link"
            class="text-typography-primary p-0"
            @click.stop="paymentInvoicesModalPayment = rowData"
          >
            <template v-if="billingsLeftToBePaid.length !== billedAmounts.length">
              <p>
                {{
                  $t('operationManagement.ongoingOperations.table.billingCountField', {
                    total: billedAmounts.length,
                    leftToPay: billingsLeftToBePaid.length,
                  })
                }}
              </p>
            </template>
            <template v-else>
              <p>{{ billedAmounts.length }}</p>
            </template>
          </Button>
          <p v-else class="text-nowrap">
            <TruncatedText>{{ $t('payment.paymentTable.notYetReceived') }}</TruncatedText>
          </p>
        </template>
        <template #cell-reconciliationStatus="{ rowData: { reconciliationsStatus, statusOverride } }">
          <ReconciliationStatus
            :is-admin="isAdmin"
            :reconciliation-status="reconciliationsStatus"
            :status-override="statusOverride"
          />
        </template>
      </Table>
    </div>
    <div class="d-flex justify-content-end my-4 overflow-hidden">
      <el-pagination
        v-if="totalImbalances > pageLimit"
        small
        layout="prev, pager, next, jumper"
        background
        :current-page.sync="currentPage"
        :page-size="pageLimit"
        :page-count="Math.ceil(totalImbalances / pageLimit)"
        :total="totalImbalances"
      />
    </div>
    <PaymentInvoicesModal
      v-if="paymentInvoicesModalPayment"
      :payment="paymentInvoicesModalPayment"
      @close="paymentInvoicesModalPayment = null"
    />
    <ReconciliationModal
      v-if="pickedReconciliationId"
      :reconciliation-id="pickedReconciliationId"
      @close="$emit('reconciliation-modal-close')"
    />
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { isNil, includes } from 'ramda';
import { ref, computed, watchEffect, watch, getCurrentInstance } from 'vue';

import { useTenancy } from '@/modules/auth';
import { Table, Button, ExcelExport } from '@/modules/core';
import { CreditCardIcon, ChequeIcon, BankTransferIcon, RecurringIcon } from '@/assets/icons';
import { RECONCILIATION_STATUSES } from '@/modules/reconciliation';
import { TruncatedText, TableLoadingSkeleton, DropdownTableFilter, DatePickerTableFilter } from '@/modules/core';
import { RECONCILIATION_STATUS_COLOR } from '@/modules/reconciliation/reconciliationStatus';
import { options } from '@/locale/dateConfig';
import { useCurrency } from '@/modules/core/compositions/money-currency';
import { exportToExcel } from '@/imports/ui/file_exports';

import ReconciliationStatus from './ReconciliationStatus';
import { PaymentDueDate, PaymentTerm } from '../../components';
import { formatDate, formatBilledDate } from '../../tools/formatters';
import { PAYMENT_STATUS, PAYMENT_METHOD_TYPE } from '../../types';

const TABLE_HEADER = {
  PAYMENT_DUE_DATE: 'paymentDueDate',
  PAYMENT_TERM: 'paymentTerm',
  CUSTOMER_NAME: 'customerName',
  BILLED_AMOUNTS: 'billedAmounts',
  RECONCILIATION_PERIOD: 'reconciliationPeriod',
  TOTAL_PAYMENT: 'totalPayment',
  PAYMENT_STATUS: 'paymentStatus',
  RECONCILIATION_STATUS: 'reconciliationStatus',
};

const PAYMENT_METHODS = {
  BANK_TRANSFER: 'bankTransfer',
  BANK_TRANSFER_DEBIT: 'bankTransferDebit',
  CREDIT_CARD: 'creditCard',
  CREDIT_CARD_DEBIT: 'creditCardDebit',
  CHECK: 'check',
};

const getSorting = ({ column, direction }) => {
  switch (column) {
    case TABLE_HEADER.CUSTOMER_NAME:
      return (a, b) => a.customer.name.localeCompare(b.customer.name) * direction;

    default:
      return ({ paymentDueDate: dateA, customer: customerA }, { paymentDueDate: dateB, customer: customerB }) => {
        // first by due date
        if (dateA < dateB) return -1;
        if (dateA > dateB) return 1;
        // second by customer name
        if (!customerA || !customerB) return 1;
        return customerA.name.localeCompare(customerB.name);
      };
  }
};

export default {
  components: {
    Table,
    TableLoadingSkeleton,
    Button,
    ExcelExport,
    DatePickerTableFilter,
    DropdownTableFilter,
    CreditCardIcon,
    ChequeIcon,
    BankTransferIcon,
    RecurringIcon,
    ReconciliationStatus,
    TruncatedText,
    PaymentDueDate,
    PaymentTerm,
    ReconciliationModal: () => import('@/modules/reconciliation/reconciliationModal/ReconciliationModal'),
    PaymentInvoicesModal: () => import('./PaymentInvoicesModal'),
    TotalPaymentField: () => import('./TotalPaymentField'),
  },
  props: {
    loading: { type: Boolean, default: false },
    paymentImbalances: { type: Array, default: () => [] },
    isAdmin: { type: Boolean, required: true },
    customerId: { type: String, default: null },
    toCloseModals: { type: Boolean, default: false },
    pickedReconciliationId: { type: String, default: null },
  },
  setup(props, { emit }) {
    const { $i18n } = getCurrentInstance().proxy;
    const { currentTenant } = useTenancy();

    const { formatToCurrency } = useCurrency();

    const paymentDueDateFilter = ref('');
    const paymentDueDateFilterText = ref('');
    const reconciliationPeriodFilter = ref('');
    const reconciliationPeriodFilterText = ref('');
    const reconciliationStatusFilter = ref('');
    const reconciliationStatusFilterText = ref('');
    const reconciliationPaymentMethodFilterText = ref('');

    const pageLimit = 50;
    const currentPage = ref(1);
    watch(
      [() => paymentDueDateFilter, reconciliationPeriodFilter, reconciliationStatusFilter],
      () => (currentPage.value = 1),
      { immediate: true }
    );
    const paymentMethodFilterList = ref([]);
    const filterByPaymentMethod = (imbalance) => {
      if (!paymentMethodFilterList.value?.length) {
        return imbalance;
      }
      const { paymentTerm } = imbalance;
      if (isNil(paymentTerm)) {
        return paymentTerm;
      }

      const { paymentMethod, directDebit } = paymentTerm;

      switch (paymentMethod) {
        case PAYMENT_METHOD_TYPE.BANK_TRANSFER: {
          if (directDebit) {
            return includes(PAYMENT_METHODS.BANK_TRANSFER_DEBIT, paymentMethodFilterList.value);
          }
          return includes(PAYMENT_METHODS.BANK_TRANSFER, paymentMethodFilterList.value);
        }
        case PAYMENT_METHOD_TYPE.CREDIT_CARD: {
          if (directDebit) {
            return includes(PAYMENT_METHODS.CREDIT_CARD_DEBIT, paymentMethodFilterList.value);
          }
          return includes(PAYMENT_METHODS.CREDIT_CARD, paymentMethodFilterList.value);
        }
        case PAYMENT_METHOD_TYPE.CHEQUE: {
          return includes(PAYMENT_METHODS.CHECK, paymentMethodFilterList.value);
        }
      }
    };

    const filteredImbalances = computed(() => {
      return props.paymentImbalances
        .filter((imbalance) => {
          const filterByDueDate = imbalance.paymentDueDate
            ? DateTime.fromISO(imbalance.paymentDueDate).toFormat('yyyy-LL') === paymentDueDateFilter.value
            : false;

          return paymentDueDateFilter.value ? filterByDueDate : true;
        })
        .filter((imbalance) => {
          if (reconciliationPeriodFilter.value) {
            const billedWithDate = imbalance.billedAmounts.length ? imbalance.billedAmounts : imbalance.unbilledOrders;
            const billedDate = DateTime.fromJSDate(formatBilledDate(billedWithDate, true)).toUTC().toFormat('yyyy-LL');
            return billedDate === reconciliationPeriodFilter.value;
          }
          return true;
        })
        .filter((imbalance) => {
          if (reconciliationStatusFilter.value) {
            if (imbalance.statusOverride) {
              return imbalance.statusOverride.status === reconciliationStatusFilter.value;
            }

            return imbalance.status.status === reconciliationStatusFilter.value;
          }
          return true;
        })
        .filter(filterByPaymentMethod);
    });

    const activeSort = ref({});
    const sortedImbalances = computed(() => [...filteredImbalances.value].sort(getSorting(activeSort.value)));

    const paginatedImbalances = computed(() =>
      sortedImbalances.value.slice((currentPage.value - 1) * pageLimit, currentPage.value * 50)
    );

    watchEffect(() => emit('view-change', paginatedImbalances.value));

    watch(
      () => props.customerId,
      () => {
        reconciliationPeriodFilter.value = '';
        paymentDueDateFilter.value = '';
        reconciliationStatusFilter.value = '';
        currentPage.value = 1;
      },
      { immediate: true }
    );

    const paymentInvoicesModalPayment = ref(null);

    watch(
      () => props.toCloseModals,
      (toCloseModals) => {
        if (toCloseModals) {
          paymentInvoicesModalPayment.value = null;
        }
      },
      { immediate: true }
    );

    const getISOMonth = (date = DateTime.now()) => date.toUTC().toFormat('yyyy-MM');

    const pickerOptions = {
      shortcuts: [
        {
          text: $i18n.t('bills.currentMonth'),
          onClick: (vm) => {
            vm.$emit('pick', getISOMonth());
          },
        },
        {
          text: $i18n.t('bills.previousMonth'),
          onClick: (vm) => {
            const date = DateTime.now().minus({ months: 1 });
            vm.$emit('pick', getISOMonth(date));
          },
        },
      ],
    };

    return {
      formatToCurrency,
      currentTenant,
      filterByPaymentMethod,
      paymentMethodFilterList,
      RECONCILIATION_STATUSES,
      RECONCILIATION_STATUS_COLOR,
      PAYMENT_STATUS,
      PAYMENT_METHODS,
      currentPage,
      pageLimit,
      sortedImbalances,
      paginatedImbalances,
      totalImbalances: computed(() => filteredImbalances.value.length),
      paymentsToExecute: ref([]),
      isExecution: ref(false),
      activeDueDatePopover: ref(null),
      pickerOptions,
      paymentDueDateFilter,
      paymentDueDateFilterText,
      reconciliationPeriodFilter,
      reconciliationPeriodFilterText,
      reconciliationStatusFilter,
      reconciliationStatusFilterText,
      reconciliationPaymentMethodFilterText,
      paymentInvoicesModalPayment,
      rowSelectionByPageMap: ref({}),
      PAYMENT_METHOD_TYPE,
      activeSort,
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.$t('payment.paymentTable.headers.paymentDueDate'),
          label: this.$t('payment.paymentTable.headers.paymentDueDate'),
          key: TABLE_HEADER.PAYMENT_DUE_DATE,
          width: this.$direction === 'ltr' ? '130px' : '90px',
          filterActive: !!this.paymentDueDateFilter,
        },
        {
          header: this.$t('payment.paymentTable.paymentTerms'),
          label: this.$t('payment.paymentTable.paymentTerms'),
          key: TABLE_HEADER.PAYMENT_TERM,
          width: '120px',
        },
        {
          header: this.$t('payment.paymentTable.headers.customerName'),
          label: this.$t('payment.paymentTable.headers.customerName'),
          key: TABLE_HEADER.CUSTOMER_NAME,
          width: '180px',
          sortCallback: (direction) => (this.activeSort = { column: TABLE_HEADER.CUSTOMER_NAME, direction }),
        },
        {
          header: this.$t('payment.paymentTable.headers.reconciliationPeriod'),
          label: this.$t('payment.paymentTable.headers.reconciliationPeriod'),
          key: TABLE_HEADER.RECONCILIATION_PERIOD,
          width: '130px',
          filterActive: !!this.reconciliationPeriodFilter,
        },
        {
          header: this.$t('payment.paymentTable.headers.totalInvoices'),
          label: this.$t('payment.paymentTable.headers.totalInvoices'),
          key: TABLE_HEADER.BILLED_AMOUNTS,
          width: '90px',
        },
        {
          header: this.$t('payment.paymentTable.headers.totalPayment'),
          label: this.$t('payment.paymentTable.headers.totalPayment'),
          key: TABLE_HEADER.TOTAL_PAYMENT,
          width: '210px',
        },

        {
          header: this.$t('payment.paymentTable.headers.reconciliationStatus'),
          label: this.$t('payment.paymentTable.headers.reconciliationStatus'),
          key: TABLE_HEADER.RECONCILIATION_STATUS,
          width: '110px',
        },
      ];
    },
    tableData() {
      return this.paginatedImbalances.filter(this.filterByPaymentMethod).map((imbalance) => {
        const {
          billingIds,
          paymentDueDate,
          paymentTerm,
          billedAmounts,
          paidAmounts,
          totalBilledAmount,
          totalPaidAmount,
          paymentStatuses,
          overdue,
          customer,
          billingsLeftToBePaid,
          unbilledOrders,
          id,
          periodStart,
          periodEnd,
          closed,
          status,
          statusOverride,
        } = imbalance;

        const isDaily = periodStart === periodEnd;

        return {
          id,
          [TABLE_HEADER.PAYMENT_DUE_DATE]: paymentDueDate,
          [TABLE_HEADER.CUSTOMER_NAME]: customer?.name ?? '-',
          [TABLE_HEADER.RECONCILIATION_PERIOD]: this.formatDateShort(periodStart, isDaily),
          [TABLE_HEADER.BILLED_AMOUNTS]: billedAmounts,
          [TABLE_HEADER.TOTAL_PAYMENT]: { totalBilledAmount, totalPaidAmount },
          [TABLE_HEADER.PAYMENT_STATUS]: this.paymentDueNetIsNotDefined(paymentTerm) ? null : paymentStatuses,
          overdue,
          paymentTerm,
          unbilledOrders,
          billingsLeftToBePaid,
          billingIds,
          paidAmounts,
          customer,
          reconciliationsStatus: status,
          statusOverride,
          reconciliationClosed: closed,
          periodStart,
          isDaily,
          currentStatus: statusOverride?.status ?? status?.status,
        };
      });
    },
  },
  mounted() {
    document.addEventListener('click', this.handleOuterClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleOuterClick);
  },
  methods: {
    paymentDueNetIsNotDefined(paymentTerm) {
      return !paymentTerm || (paymentTerm && !Number.isInteger(paymentTerm.paymentDueNet));
    },
    formatDateShort(date, isDaily) {
      if (!date) return '';
      if (isDaily) return new Date(date).toLocaleDateString(this.$i18n.locale, { ...options.twoDigits });
      return new Date(date).toLocaleDateString(this.$i18n.locale, {
        month: 'short',
        year: '2-digit',
        timeZone: 'UTC',
      });
    },
    formatDate,
    handleDueDateClick(rowIndex) {
      this.activeDueDatePopover = this.activeDueDatePopover === rowIndex ? null : rowIndex;
    },
    handleOuterClick() {
      this.activeDueDatePopover = null;
    },
    onPaymentDueDateFilterChanged(date) {
      this.paymentDueDateFilter = date ?? null;
      this.paymentDueDateFilterText = date ? DateTime.fromISO(date).toFormat('MMM yy') : null;
    },
    onBillingDateFilterChanged(date) {
      this.reconciliationPeriodFilter = date ?? null;
      this.reconciliationPeriodFilterText = date ? DateTime.fromISO(date).toFormat('MMM yy') : null;
    },
    onReconciliationStatusFilterChanged(command) {
      this.reconciliationStatusFilter = command ?? null;
      this.reconciliationStatusFilterText = command ? this.$i18n.t(`reconciliation.exports.status.${command}`) : null;
    },
    onReconciliationPaymentMethodFilterChanged() {
      if (!this.paymentMethodFilterList?.length) {
        this.reconciliationPaymentMethodFilterText = '';
      } else if (this.paymentMethodFilterList?.length === 1) {
        const [filterTitle] = this.paymentMethodFilterList;
        this.reconciliationPaymentMethodFilterText = this.$t(
          `payment.paymentTable.filtersPaymentMethod.${filterTitle}`
        );
      } else if (this.paymentMethodFilterList?.length > 1) {
        this.reconciliationPaymentMethodFilterText = `(${this.paymentMethodFilterList?.length})`;
      }
      this.currentPage = 1;
    },
    onReconciliationPaymentMethodFilterReset() {
      this.reconciliationPaymentMethodFilterText = null;
      this.paymentMethodFilterList = [];
    },
    exportExcel() {
      const { PAYMENT_STATUS } = TABLE_HEADER;
      const columns = this.columns.filter(({ key }) => ![PAYMENT_STATUS].includes(key));
      const metadata = {
        filename: this.$t('routes.payments'),
        sheetname: this.$t('payment.paymentTable.paymentsForApproval'),
        direction: this.$t('direction'),
        columns,
      };

      const data = this.sortedImbalances.map(
        ({
          paymentDueDate,
          customer,
          periodStart,
          periodEnd,
          status,
          statusOverride,
          billingsLeftToBePaid,
          billedAmounts,
          totalBilledAmount,
          totalPaidAmount,
          paymentTerm,
        }) => {
          const paymentTerms = () => {
            if (!paymentTerm) return this.$t('payment.paymentTable.notDefined');
            const paymentTerms = [];
            if (paymentTerm.paymentMethod) {
              const paymentMethod = this.$t(`payment.exports.paymentMethods.fullName.${paymentTerm.paymentMethod}`);
              if (paymentTerm.directDebit) {
                const directDebit = this.$t('payment.paymentTable.directDebit');
                paymentTerms.push(`${paymentMethod} - ${directDebit}`);
              } else paymentTerms.push(paymentMethod);
            }
            if (!this.paymentDueNetIsNotDefined(paymentTerm))
              paymentTerms.push(
                this.$tc(
                  `payment.paymentTable.paymentTermsPopover.paymentDueNet${paymentTerm.eom ? 'Eom' : ''}`,
                  paymentTerm.paymentDueNet,
                  {
                    count: paymentTerm.paymentDueNet,
                  }
                )
              );
            return paymentTerms.join(', ');
          };
          const invoicesToPay = () => {
            if (billedAmounts.length) {
              return billingsLeftToBePaid.length !== billedAmounts.length
                ? this.$t('operationManagement.ongoingOperations.table.billingCountField', {
                    total: billedAmounts.length,
                    leftToPay: billingsLeftToBePaid.length,
                  })
                : billedAmounts.length;
            } else return this.$t('payment.paymentTable.notYetReceived');
          };
          const calculatedStatus = statusOverride?.status ?? status?.status;

          return {
            [TABLE_HEADER.PAYMENT_DUE_DATE]: this.formatDate(paymentDueDate),
            [TABLE_HEADER.PAYMENT_TERM]: paymentTerms(),
            [TABLE_HEADER.CUSTOMER_NAME]: customer.name,
            [TABLE_HEADER.RECONCILIATION_PERIOD]: this.formatDateShort(periodStart, periodStart === periodEnd),
            [TABLE_HEADER.BILLED_AMOUNTS]: invoicesToPay(),
            [TABLE_HEADER.TOTAL_PAYMENT]: totalBilledAmount - totalPaidAmount,
            [TABLE_HEADER.RECONCILIATION_STATUS]: calculatedStatus
              ? this.$t(`billing.exports.status.${calculatedStatus}`)
              : '-',
          };
        }
      );

      exportToExcel(metadata, data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.warning-button {
  padding-top: 2px;
}

button.text-warning.warning:not(:hover) {
  color: change-color($color: $warning, $lightness: 38.1%) !important;
}
button.text-danger.danger:not(:hover) {
  color: change-color($color: $error, $lightness: 40%) !important;
}

.badge-position {
  top: 25%;
  [dir='rtl'] & {
    left: 100%;
    transform: translate(-50%, -50%);
  }
  [dir='ltr'] & {
    left: 0;
    transform: translate(-50%, -50%) scaleX(-1);
  }
}
.table-filters {
  display: flex;
  gap: 0.5rem;
}

.reconciliation-status-filter {
  display: flex;
  gap: 0.25rem;
  align-items: center;

  .reconciliation-status-filter-icon {
    height: 8px;
    width: 8px;
    padding: 0.25rem;
    border-radius: 50%;
  }
}

.reconciliation-payment-method-dropdown {
  .el-checkbox-group {
    display: flex;
    flex-direction: column;
    padding: 0px 10px;

    .el-checkbox {
      margin: 0;
      padding: 5px 0px;
    }
  }

  .reconciliation-payment-method-filter {
    display: flex;
    align-items: center;
    position: relative;
    font-weight: normal;
    color: rgb(31, 40, 77);

    .payment-method-title {
      margin: 0px 5px;
    }

    .reconciliation-payment-method-filter-icon {
      .method-icon {
        width: 16px;
        height: 16px;
      }
      .recurring-icon {
        position: absolute;
        width: 12px;
        height: 12px;
        top: -2px;

        [dir='rtl'] & {
          right: -5px;
        }
        [dir='ltr'] & {
          left: -5px;
        }
      }
    }
  }
}

.payments-table-title {
  height: 2rem;
}

.top-section {
  top: $page-layout-dashboard-hight;
  padding: 1rem 0;
}
</style>
