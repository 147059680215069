<template>
  <div>
    <div class="d-flex justify-content-between mb-4">
      <h3>
        {{ $t('payment.pastPayments.tabs.matchedPayments') }}
      </h3>
    </div>
    <div>
      <TableLoadingSkeleton v-if="loading" />
      <Table
        v-else
        :columns="columns"
        :data="tableData"
        show-index
        border
        rounded
        class="mb-4"
        @row-click="handleRowClick"
      >
        <template #cell-index="{ rowIndex }">
          {{ rowIndex + 1 + pageSize * (currentPage - 1) }}
        </template>
        <template #cell-paymentDate="{ rowData: { paymentDate } }">
          <p>{{ formatDate(paymentDate) }}</p>
        </template>
        <template #cell-paidAmount="{ rowData: { paidAmount } }">
          <p>{{ formatMoney(paidAmount) }}</p>
        </template>
      </Table>
    </div>
    <el-pagination
      v-if="totalCount > pageSize"
      :current-page.sync="currentPage"
      small
      layout="prev, pager, next, jumper"
      background
      :page-size="pageSize"
      :total="totalCount"
      class="d-flex justify-content-end p-0"
    />
    <EventMapModal v-if="activity" :activity="activity" @close="activity = null" />
  </div>
</template>

<script>
import { ref, computed } from 'vue';

import { Table, TableLoadingSkeleton } from '@/modules/core';
import { useBusinessByNames } from '@/modules/business/compositions/business';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { usePayments } from './pastPayments/compositions/payment';
import { formatBilledDate, formatDate, formatDateTime } from '../../tools/formatters';

const TABLE_HEADER = {
  PAYMENT_DATE: 'paymentDate',
  PAYMENT_METHOD: 'paymentMethod',
  CUSTOMER: 'customerName',
  INVOICE_COUNT: 'invoiceCount',
  INVOICES_DATE: 'invoicesDate',
  PAID_AMOUNT: 'paidAmount',
};

const PAYMENTS_DEFAULT_LIMIT = 50;

export default {
  components: { Table, TableLoadingSkeleton, EventMapModal: () => import('@/modules/eventMapModal/EventMapModal') },
  props: {
    supplier: { type: Object, required: true },
    customerId: { type: String, default: null },
  },
  setup(props) {
    const currentPage = ref(1);
    const { formatToCurrency } = useCurrency();

    const pageSize = PAYMENTS_DEFAULT_LIMIT;
    const {
      payments,
      totalCount,
      loading: paymentsLoading,
      refetch: refetchPayments,
    } = usePayments(
      computed(() => ({
        completed: true,
        limit: pageSize,
        offset: (currentPage.value - 1) * pageSize,
        supplierId: props.supplier.id,
        businessId: props.customerId ? props.customerId : undefined,
        isCustomerSide: true,
      })),
      () => ({ enabled: Boolean(props.supplier.id) })
    );

    const customerIds = computed(() => payments.value.map(({ customerId }) => customerId));
    const { result: businesses, loading: businessesLoading } = useBusinessByNames({ businessIds: customerIds });

    const formatMoney = (value) => {
      if (typeof value === 'number' && !Number.isNaN(value)) {
        const number = Number(value.toFixed(2));
        const options = Number.isInteger(number) ? { maximumFractionDigits: 0 } : {};
        return formatToCurrency(value, options);
      }
      return '-';
    };

    return {
      businesses,
      pageSize,
      loading: computed(() => paymentsLoading.value),
      businessesLoading,
      currentPage,
      payments,
      refetchPayments,
      totalCount,
      formatMoney,
      formatDateTime,
      activity: ref(),
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.translate(`table.headers.${TABLE_HEADER.PAYMENT_DATE}`),
          key: TABLE_HEADER.PAYMENT_DATE,
          width: '100px',
        },
        {
          header: this.translate(`table.headers.${TABLE_HEADER.CUSTOMER}`),
          key: TABLE_HEADER.CUSTOMER,
          width: '150px',
        },
        {
          header: this.translate(`table.headers.${TABLE_HEADER.INVOICE_COUNT}`),
          key: TABLE_HEADER.INVOICE_COUNT,
          width: '80px',
        },
        {
          header: this.translate(`table.headers.${TABLE_HEADER.INVOICES_DATE}`),
          key: TABLE_HEADER.INVOICES_DATE,
          width: '110px',
        },
        {
          header: this.translate(`table.headers.${TABLE_HEADER.PAID_AMOUNT}`),
          key: TABLE_HEADER.PAID_AMOUNT,
          width: '120px',
        },
        {
          header: this.translate(`table.headers.${TABLE_HEADER.PAYMENT_METHOD}`),
          key: TABLE_HEADER.PAYMENT_METHOD,
          width: '130px',
        },
      ];
    },
    tableData() {
      return this.payments.map(
        ({ id, customerId, date, billingLinks, amount, isRefund, transactions, initialBalance }) => {
          const customer = this.businesses?.find(({ id }) => id === customerId);

          let paymentMethod;
          if (transactions.length) {
            paymentMethod = `${this.$t(`payment.exports.paymentMethods.shortName.${transactions[0].paymentMethod}`)}${
              transactions[0].directDebit ? ` - ${this.translate('directDebit')}` : ''
            }`;
          } else {
            if (!initialBalance) {
              paymentMethod = '-';
            } else {
              paymentMethod = this.translate(`noPaymentMethod`);
            }
          }

          return {
            id,
            [TABLE_HEADER.PAYMENT_DATE]: date,
            [TABLE_HEADER.PAYMENT_METHOD]: paymentMethod,
            [TABLE_HEADER.CUSTOMER]: customer?.name ?? '-',
            [TABLE_HEADER.INVOICE_COUNT]: billingLinks.length,
            [TABLE_HEADER.INVOICES_DATE]: formatBilledDate(billingLinks.map(({ billing }) => billing)),
            [TABLE_HEADER.PAID_AMOUNT]: amount * (isRefund ? -1 : 1),
          };
        }
      );
    },
  },
  methods: {
    translate(key) {
      return this.$t(`payment.pastPayments.${key}`);
    },
    formatDate,
    handleRowClick(index) {
      this.activity = {
        type: 'payment',
        id: this.tableData[index].id,
      };
    },
  },
};
</script>
